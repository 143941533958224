/**
 * icon types = 'default', 'rounded', 'outline', 'sharp'
 *
 */
module.exports = {
  theme: 'clockwork',
  config: {
    show_utility_bar: false
  },
  icons: {
    type: 'default',
    custom: {
      ic_tune: {
        viewbox: '0 0 24 24',
        content: '<path xmlns="http://www.w3.org/2000/svg" d="M9.002 12.826l-7.764-9.18C.688 2.996 1.151 2 2.002 2h20a1 1 0 01.763 1.646l-7.763 9.18V21a1 1 0 01-1.447.894l-4-2A.997.997 0 019.002 19v-6.174zM19.847 4H4.157l6.608 7.814a1 1 0 01.237.646v5.922l2 1V12.46c0-.236.084-.465.236-.646L19.847 4z" fill="currentColor"/>'
      },
    }
  },
  colors: {
    brand_1: {
      name: 'Red',
      value: '#ff0000'
    },
    brand_2: {
      name: 'Blue',
      value: '#0000ff'
    },
    mono_1: {
      name: 'White',
      value: '#fff'
    },
    mono_2: {
      name: 'Black',
      value: '#000'
    },
    error: {
      name: 'Error',
      value: '#cc0000'
    },
    info: {
      name: 'Info',
      value: '#0099cc'
    },
    success: {
      name: 'Success',
      value: '#007e33'
    },
    warning: {
      name: 'Warning',
      value: '#ff8800'
    }
  },
  fonts: {
    font_1: {
      name: 'Serif',
      value: 'Georgia, "Times New Roman", Times, serif'
    },
    font_2: {
      name: 'Sans-serif',
      value: 'Arial, Helvetica, sans-serif'
    },
    font_3: {
      name: 'Modern',
      value: 'Tahoma, Verdana, Geneva, sans-serif'
    },
    font_4: {
      name: 'Monospace',
      value: '"Courier New", Courier, monospace'
    }
  }
}
